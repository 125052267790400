import React from "react";

const ShowAdButton = () => {
  const showAd = () => {
    // Check if Monetag's function is loaded
    if (window.show_8800218) {
      window.show_8800218().then(() => {
        alert("You have seen the ad!");
      }).catch((error) => {
        console.error("Error displaying ad:", error);
      });
    } else {
      alert("Ad script is not loaded yet.");
    }
  };

  return <button onClick={showAd}>Show Rewarded Ad</button>;
};

export default ShowAdButton;