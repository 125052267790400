import React, { useState, useEffect, useRef } from 'react';

const slides = [
  {
    title: 'WATCH ADS',
    description: 'Watch ads to earn rewards',
    link: '/checkin',
  },
  {
    title: 'OKAPI FARMING COMMUNITY',
    description: 'Join Okapi community channel',
    link: 'https://t.me/okapicommunity',
  },
];

const CommunitySlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [userBalance, setUserBalance] = useState(0); // User's Okapi balance
  const [adsWatchedToday, setAdsWatchedToday] = useState(0); // Ads watched today
  const slideInterval = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Fetch initial data from localStorage
  useEffect(() => {
    const savedBalance = parseInt(localStorage.getItem('okapiBalance') || '0', 10);
    const savedDate = localStorage.getItem('lastWatchedDate');
    const today = new Date().toDateString();

    if (savedDate === today) {
      const savedAds = parseInt(localStorage.getItem('adsWatchedToday') || '0', 10);
      setAdsWatchedToday(savedAds);
    } else {
      localStorage.setItem('lastWatchedDate', today);
      localStorage.setItem('adsWatchedToday', '0');
    }

    setUserBalance(savedBalance);
  }, []);

  const startSlideInterval = () => {
    slideInterval.current = setInterval(() => {
      handleNextSlide();
    }, 5000);
  };

  const stopSlideInterval = () => {
    clearInterval(slideInterval.current);
  };

  useEffect(() => {
    startSlideInterval();
    return () => stopSlideInterval();
  }, []);

  const handleNextSlide = () => {
    setIsTransitioning(true);
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  const handlePrevSlide = () => {
    setIsTransitioning(true);
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  const handleDotClick = (index) => {
    stopSlideInterval();
    setIsTransitioning(true);
    setCurrentSlide(index);
    startSlideInterval();
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e) => {
    touchEndX.current = e.changedTouches[0].screenX;
    handleSwipe();
  };

  const handleSwipe = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      handleNextSlide();
    } else if (touchEndX.current - touchStartX.current > 50) {
      handlePrevSlide();
    }
  };

  useEffect(() => {
    if (isTransitioning) {
      const transitionEnd = setTimeout(() => {
        setIsTransitioning(false);
        if (currentSlide >= slides.length) {
          setCurrentSlide(0);
        } else if (currentSlide < 0) {
          setCurrentSlide(slides.length - 1);
        }
      }, 500); // Duration of the transition
      return () => clearTimeout(transitionEnd);
    }
  }, [currentSlide, isTransitioning]);

  const showAd = () => {
    const maxAdsPerDay = 5;

    if (adsWatchedToday >= maxAdsPerDay) {
      alert("You have reached the daily limit of 5 ads.");
      return;
    }

    if (window.show_8800218) {
      window.show_8800218()
        .then(() => {
          // Reward the user with Okapi tokens
          const newBalance = userBalance + 10000;
          const newAdsCount = adsWatchedToday + 1;

          setUserBalance(newBalance);
          setAdsWatchedToday(newAdsCount);

          // Update localStorage
          localStorage.setItem('okapiBalance', newBalance.toString());
          localStorage.setItem('adsWatchedToday', newAdsCount.toString());

          alert("You have earned 10,000 Okapi tokens!");
        })
        .catch((error) => {
          console.error("Error displaying ad:", error);
          alert("Failed to load the ad.");
        });
    } else {
      alert("Ad script is not loaded yet.");
    }
  };

  return (
    <div className="relative w-full max-w-xl mx-auto overflow-hidden">
      <div
        className={`flex ${isTransitioning ? 'transition-transform duration-500' : ''}`}
        style={{ transform: `translateX(-${(currentSlide % slides.length) * 90}%)` }} // Adjust 100% to 90% for partial view
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {slides.concat(slides[0]).map((slide, index) => (
          <div key={index} className="min-w-[90%]">
            <div className="bg-[#17181A] mr-4 rounded-[12px] py-6 px-4 flex flex-col">
              <h2 className="font-medium">{slide.title}</h2>
              <p className="pb-2 text-[14px]">{slide.description}</p>

              {index === 0 ? (
                <button
                  onClick={showAd}
                  className="bg-btn4 py-1 px-3 text-[16px] font-semibold w-fit rounded-[30px]"
                >
                  Watch Ad
                </button>
              ) : (
                <a
                  href={slide.link}
                  className="bg-btn4 py-1 px-3 text-[16px] font-semibold w-fit rounded-[30px]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join
                </a>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-3 space-x-2">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`w-2 h-2 rounded-full cursor-pointer ${
              index === (currentSlide % slides.length) ? 'bg-white' : 'bg-gray-400'
            }`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>

      <div className="text-center mt-4">
        <p className="text-white font-medium">Your Okapi Tokens: {userBalance}</p>
        <p className="text-gray-400">Ads Watched Today: {adsWatchedToday}/5</p>
      </div>
    </div>
  );
};

export default CommunitySlider;